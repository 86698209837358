import { Flex, Heading, Divider, Text, Image } from '@chakra-ui/react'
import { useContext } from 'react'

import { TabContext } from './TabContext'

const DotWithLink = ({ text, value }: { text: string; value: string }) => {
  const { setSelectedTab } = useContext(TabContext)

  const setTab = (value: string) => {
    setSelectedTab(value)
    document?.querySelector(`#actions`)?.scrollIntoView()
  }

  return (
    <Flex direction="row" w="full" columnGap={3} alignItems="center">
      <Flex bg="blue.500" w="10px" h="10px" borderRadius="50%" />
      <Text color="green.300" size="xl" onClick={() => setTab(value)} _hover={{ cursor: 'pointer' }}>
        {text}
      </Text>
    </Flex>
  )
}

const Ambition = () => {
  return (
    <Flex direction={{ base: 'column-reverse', lg: 'row' }} w="full" py={12} rowGap={6} id="ambition">
      <Flex w="full" justifyContent="center">
        <Image alt="" src={'images/mask.png'} w={{ base: '223px', lg: '447px' }} h={{ base: '223px', lg: '447px' }} />
      </Flex>
      <Flex w="full" direction="column" rowGap={5} px={10} justifyContent="center">
        <Heading size="md" color="green.300">
          L’ambition du Programme Avenir Lait 2025
        </Heading>
        <Divider borderColor="red" w="50px" borderWidth="1px" opacity={1} />
        <Text size="lg" color="fakeblack">
          En 2023 une démarche collaborative a été lancée entre l&apos;association Agrimousquetaires et les Agriculteurs
          Partenaires du Groupement Mousquetaires pour co-construire le premier Programme Avenir Lait 2025 dont les cinq
          premiers appels à projets sont :
        </Text>
        <Flex direction="column" w="full" rowGap={4}>
          <DotWithLink text="Aide au remplacement pour congés vacances" value="holiday" />
          <DotWithLink text="Pack à l’installation des Jeunes Agriculteurs" value="agri" />
          <DotWithLink text="Aide aux équipements à réduction énergie" value="energy" />
          <DotWithLink text="Diagnostic Autoconsommation" value="diagnostic" />
          <DotWithLink text="Amélioration des conditions de travail / sécurité" value="working" />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Ambition
