import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'

import { CenteredDotWithText, GreenBloc } from '@components'

const Diagnostic = () => {
  return (
    <Flex w="full" direction="column" py={{ base: 3, lg: 12 }} px={{ base: 0, lg: 36 }} bg="pink" rowGap={8}>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Pour être éligible
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Remplir les critères d’éligibilité du programme Avenir Lait d’Agrimousquetaires" />
        <CenteredDotWithText text="Respecter la période initiale et la période du renouvellement du contrat d’approvisionnement que vous avez conclu avec la laiterie" />
        <CenteredDotWithText text="S’engager à réaliser un diagnostic d’autoconsommation à compter du 1er janvier 2025" />
        <CenteredDotWithText text="Fournir l’attestation “Diagnostic Autoconsommation” délivrée par la laiterie et la facture associée" />
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={12} rowGap={6}>
        <GreenBloc
          iconName={'ForfaitAgri'}
          text={'Jusqu’à 1 000 € HT '}
          subTexts={[
            'de soutien financier pour réaliser à compter du 1er janvier 2023, un diagnostic d’autoconsommation par un cabinet de conseil référencé et partenaire du groupement Mousquetaires (sur présentation d’une facture).',
          ]}
          bolded={true}
        />
      </Flex>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Documents à fournir
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Facture du diagnostic autoconsommation réalisé par le cabinet de conseil à compter du 1er janvier 2025 (<i>dont la subvention est plafonnée à hauteur de 1 000€ HT</i>)" />
      </Flex>
      <Link href="#form" alignSelf="center">
        <Button variant="primary">
          <Text size="md" fontWeight="400">
            Envoyer ma demande de contact
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default Diagnostic
