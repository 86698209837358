import { Flex, Heading, Text, Button, Link } from '@chakra-ui/react'

import { CenteredDotWithText, GreenBloc } from '@components'

const Working = () => {
  return (
    <Flex w="full" direction="column" py={{ base: 3, lg: 12 }} px={{ base: 0, lg: 36 }} bg="pink" rowGap={8}>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Pour être éligible
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="Remplir les critères d’éligibilité du programme Avenir Lait d’Agrimousquetaires" />
        <CenteredDotWithText text="Respecter la période initiale et la période du renouvellement du contrat d’approvisionnement que vous avez conclu avec la laiterie" />
        <CenteredDotWithText text="Fournir l’attestation délivrée par la laiterie et la facture associée pour l’acquisition d’équipements réduisant la pénibilité au travail selon les conditions définies ci-dessous* à compter du 1er janvier 2025" />
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={12} rowGap={6}>
        <GreenBloc
          iconName={'ForfaitAgri'}
          text={'Jusqu’à 4 000 € HT'}
          subTexts={[
            '<strong>de soutien financier à hauteur de 50% du prix</strong> pour l’acquisition d’équipements améliorant la sécurité et réduisant la pénibilité au travail',
          ]}
          bolded={true}
          // maxW={{ base: 'full', md: '600px' }}
        />
        <GreenBloc
          iconName={'ForfaitAgri'}
          text={'Liste des équipements éligibles'}
          subTexts={[
            'Exosquelette',
            'Balayette à logettes',
            'Repousse fourrage',
            'Griffe avec poids allégé ou avec “démarrage automatique du vide et de la pulsation”',
            'Brouettes à veau',
            'Pont mobile pour la traite',
            'Cage de contention',
            'Chariots bidon de lait / Taxi à lait',
          ]}
          bolded={false}
          // maxW={{ base: 'full', md: '600px' }}
        />
      </Flex>
      <Heading size="xs" color="red" textAlign={{ base: 'center', lg: 'start' }}>
        Documents à fournir
      </Heading>
      <Flex direction="column" w="full" rowGap={8} px={{ base: 0, lg: 12 }}>
        <CenteredDotWithText text="La facture associée pour l’acquisition d’équipements améliorant la sécurité et réduisant la pénibilité au travail à compter du 1er janvier 2025, selon les conditions définies ci-dessus" />
      </Flex>
      <Link href="#form" alignSelf="center">
        <Button variant="primary">
          <Text size="md" fontWeight="400">
            Envoyer ma demande de contact
          </Text>
        </Button>
      </Link>
    </Flex>
  )
}

export default Working
