import { MinusIcon, AddIcon } from '@chakra-ui/icons'
import { AccordionButton, Flex, Text, AccordionPanel, Accordion, AccordionItem } from '@chakra-ui/react'

import { Banner } from '@components'

interface Props {
  title: string
  text: string[]
  textColor: string
  bgColor: string
  isExpanded: boolean
}

const Item = ({ title, text, textColor, bgColor, isExpanded }: Props) => {
  return (
    <Flex direction="column" bg={bgColor} w="full" h="full" borderRadius={'7px'}>
      <AccordionButton justifyContent="space-between" h={{ base: '50px', md: 'auto' }} my={3}>
        <Text size="lg" color={textColor} textAlign="start">
          {title}
        </Text>
        {isExpanded ? <MinusIcon fontSize="12px" color={textColor} /> : <AddIcon fontSize="12px" color={textColor} />}
      </AccordionButton>
      <AccordionPanel>
        <Flex direction="column" rowGap={2}>
          {text.map((t, i) => (
            <Text size="md" color={textColor} key={i}>
              {t}
            </Text>
          ))}
        </Flex>
      </AccordionPanel>
    </Flex>
  )
}

const FAQ = () => {
  return (
    <Flex direction="column" w="full" pb={20} id="faq">
      <Banner text={'FAQ'} bgColor={'white'} textColor={'green.900'} />
      <Accordion
        allowToggle
        display="flex"
        w="full"
        h="full"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection={{ base: 'column', md: 'row' }}
        columnGap={10}
        rowGap={10}
        flexWrap="wrap"
        px={6}
      >
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={1} border="0px">
          {({ isExpanded }) => (
            <Item
              title="En savoir plus sur Agrimousquetaires"
              text={[
                "En 2023 une démarche  collaborative a été lancée entre l'association Agrimousquetaires et les Agriculteurs Partenaires du Groupement Mousquetaires pour co-construire le premier Programme Avenir Lait 2025 qui a pour vocation de sécuriser leurs approvisionnements français et soutenir la transition agricole.",
              ]}
              textColor="white"
              bgColor="green.300"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={3} border="0px">
          {({ isExpanded }) => (
            <Item
              title="Qui est éligible ?"
              text={[
                'Pour être éligible à au moins l’un des cinq appels à projets, les participants doivent répondre aux conditions générales suivantes :',
                '- Être un Agriculteur Partenaire ayant une exploitation agricole qui a une relation de fourniture directe ou indirecte avec au moins une entité du Groupement Mousquetaires (Agromousquetaires, unités de production d’Agromousquetaires, Intermarché Alimentaires International ou point de vente Intermarché/Netto)',
                '- Disposer de la capacité juridique adaptée (structure agricole avec un N° SIRET actif)',
                '- Être adhérent à la Charte des Bonnes Pratiques d’Élevage et à la Charte Agromousquetaires',
              ]}
              textColor="white"
              bgColor="red"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={5} border="0px">
          {({ isExpanded }) => (
            <Item
              title="Quels engagements pour le porteur de projet ?"
              text={[
                'L’Agriculteur Partenaire s’engage à : ',
                '- s’inscrire dans une relation d’approvisionnement long terme avec le Groupement Mousquetaires',
                '- remplir les conditions des appels à projet citées plus haut',
                '- remplir les conditions de la Convention d’Accompagnement passées avec Agrimousquetaires',
                '- accepter d’être identifié(e) comme Agriculteur Partenaire participant à l’appel à projet ',
              ]}
              textColor="white"
              bgColor="blue.500"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={2} border="0px">
          {({ isExpanded }) => (
            <Item
              title="En savoir plus sur le Programme Avenir Lait 2025"
              text={[
                'Depuis 2023, Agrimousquetaires a entamé une démarche collective et collaborative auprès des adhérents du groupement Mousquetaires pour soutenir des projets favorisant l’attractivité du métier d’agriculteur ainsi que la transition énergétique et environnementale.',
              ]}
              textColor="white"
              bgColor="blue.500"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={4} border="0px">
          {({ isExpanded }) => (
            <Item
              title="Comment fonctionne l’opération ?"
              text={[
                'Si vous êtes un Agriculteur Partenaire et que vous remplissez les conditions d’éligibilité, vous pouvez postuler en remplissant notre formulaire de contact ci-dessus.',
                'Sous réserve de l’étude de votre demande de financement et de votre projet vous pourrez bénéficier d’un soutien financier d’Agrimousquetaires.',
              ]}
              textColor="white"
              bgColor="green.300"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
        <AccordionItem w={{ base: 'full', md: '500px' }} maxW={{ base: 'full', md: '500px' }} order={6} border="0px">
          {({ isExpanded }) => (
            <Item
              title="Que comprend l’accompagnement pour les lauréats ?"
              text={[
                'L’accompagnement d’Agrimousquetaires comprend un soutien financier propre à chaque appel à projets pour financer le projet de transition auquel chaque Agriculteur Partenaire est éligible.',
              ]}
              textColor="white"
              bgColor="red"
              isExpanded={isExpanded}
            />
          )}
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default FAQ
